
import { Options, Vue } from 'vue-class-component';
// import GrayHeader from '@/components/grayHeader.vue';
import { useStore, Store } from 'vuex';

@Options({
    components: {
        // GrayHeader,
    },
})
export default class flowDetail extends Vue {
    private store: Store<any> = useStore();
    private stageInfo: Array<Object> = [
        {
            title: '报名组队与实名认证',
            content: [
                '1、报名方式：注册腾讯云账号并完成实名认证，登录比赛官网/觅影开放实验平台，完成注册，即可报名参赛。',
                '2、选手可以单人参赛，也可以组队参赛。组队参赛的每个团队2-5人，每位选手只能加入一支队伍。',
                // '3、每个参赛团队最多选择1个赛题。',
                '3、选手需确保报名信息准确有效，组委会有权取消不符合条件队伍的参赛资格及奖励。',
                '4、选手报名操作截止时间为10月10日中午12点。',
            ],
        },
        {
            title: '初赛',
            content: [
                '1、所有模型训练和验证均在智能钛机器学习平台上完成，初赛训练数据和测试数据均在线上，此外提供个别数据用于线下调试算法模型。',
                '2、每个团队每周可领取代金券（购买算力和存储资源）用于模型在线训练，训练完成的模型可在线保存；每日可提交三个结果文件进行测试。排行榜每日进行更新，按照评测指标从高到低排序。排行榜将选择参赛队伍在本阶段的历史最优成绩进行排名展示。',
                '3、每次提交的算法需同时包含结果文件，训练（train）和推断（inference）代码。',
                '4、初赛截止后，组委会将进行排名前50名参赛队伍的入围审核，组委会将按照比赛规则对代码进行审核，对于不符合要求的情况终止参赛，晋级空缺名额后补。最终最多30个通过审核的队伍进入复赛环节。',
            ],
        },
        {
            title: '复赛',
            content: [
                '1、所有模型训练和验证均在智能钛机器学习平台上完成，复赛提供可同时使用初赛训练和测试数据用于模型训练，额外提供复赛测试数据。',
                '2、每个团队每周可领取代金券（用于购买算力和存储资源），训练完成的模型可在线保存；每日可提交三个结果文件进行测试。排行榜每日进行更新，按照评测指标从高到低排序。',
                '3、每次提交的算法需同时包含结果文件，训练（train）和推断（inference）代码。',
                '4、复赛截止后，组委会将进行排名前20名参赛队伍的入围审核，组委会将按照比赛规则对代码进行审核，对于不符合要求的情况终止参赛，晋级空缺名额后补。最终最多12个通过审核的队伍进入决赛环节。',
            ],
        },
        {
            title: '决赛',
            content: [
                '1、决赛将以腾讯会议答辩会的形式进行，晋级决赛团队需提前准备答辩材料，包括答辩PPT、参赛总结、算法核心代码。',
                '2、答辩现场，每支队伍面对评委有固定的陈述时间和问答时间。评委将根据选手的技术思路、理论深度和现场表现进行综合评分。',
                '3、评委由算法专家和临床医学专家组成，评分包括算法创新性、算法性能、临床实用可行性几个部分组成。',
                '4、决赛分数将根据参赛队伍的线上成绩和答辩成绩加权得出。评分权重复赛公布。依据决赛分数评选出大赛奖项。',
            ],
        },
    ];
    get showChooseChannel(): Boolean {
        return this.store.state.loginStatus.haslogin && this.store.state.userStatus.AuditState === 3;
    }
    private goPage() {
        this.$router.push('./');
    }
    chooseChannel() {
        // todo
    }
}
